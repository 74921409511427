body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.resimg {
  max-height: 350px;
}
@media only screen and (max-width: 600px) {
  .resimg {
    max-height: 200px;
  }
}

.hidescroll::-webkit-scrollbar {
  display: none;
}

.loader,
.confirmed {
  height: 100vh;
  width: 100vw;
  background: rgb(0, 0, 0, 0.7);
  backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.togglers {
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.445);
  /* border-top: 1px solid rgba(255, 255, 255, 0.445); */
  padding: 10px 10px;
  display: inline-block;
  width: 100%;
  transition: all 0.5s ease-in;
  font-size: 14px;
}

.togglersActive {
  border-bottom: 1.5px solid white;
}

.hideYscroll::-webkit-scrollbar {
  display: none;
}

.loader2 {
  border-top: 8px solid hotpink;
  border-bottom: 8px solid #f55951;
  border-left: 8px solid #3a9efd;
  border-right: 8px solid #1fff7c;
  border-radius: 100%;
  height: 100px;
  width: 100px;
  animation: looper 5s infinite ease-in-out;
}

@keyframes looper {
  0% {
    border-radius: 100% 100% 100% 100%;
    border-color: hotpink #3a9efd #1fff7c #f55951;
  }

  10% {
    border-radius: 0% 100% 0% 0%;
    border-color: #f55951;
    transform: rotate(150deg);
  }

  20% {
    border-radius: 0% 0% 0% 0%;
    border-color: #3a9efd;
  }

  30% {
    border-radius: 0% 0% 0% 0%;
    border-color: #1fff7c;
  }

  40% {
    border-radius: 0% 0% 0% 0%;
    border-color: #3a9efd;
  }

  50% {
    border-radius: 100%;
    border-color: #f55951;
  }

  90% {
    border-radius: 100%;
    transform: rotate(360deg);
    border-style: dashed;
  }

  100% {
    transform: rotate(0deg);
    border-radius: 100% 100% 100% 100%;
    border-color: hotpink #3a9efd #1fff7c #f55951;
  }
}

.miniPlayer {
  height: auto;
  width: 100%;
  position: fixed;
  bottom: 11%;
  background-color: #060727 !important;
  left: 0%;
  z-index: 9999;
  border-radius: 10px 10px 0 0;
}

.uDashdd {
  background: rgb(68 68 68);
  width: 217px;
  color: white;
}

.clipVid {
  height: 100%;
  width: 100%;
  background: black;
  mix-blend-mode: hard-light;
}
